import React from 'react'
import GridLayout from './icon-grid.styles'
import Icon from '@material-ui/core/Icon'
import { ICON_CONTENTS } from './icon-grid.constants'

const IconGrid = () => {
  return (
    <GridLayout>
      <GridLayout.gridContainer>
        {ICON_CONTENTS.map((contents) => (
          <>
            <GridLayout.iconContainer id={contents.id}>
              <Icon className='icon'>{contents.icon}</Icon>
              <GridLayout.iconTitle>{contents.title}</GridLayout.iconTitle>
              <GridLayout.iconSubtitle>
                {contents.subtitle}
              </GridLayout.iconSubtitle>
            </GridLayout.iconContainer>
          </>
        ))}
        <>
          <GridLayout.dummyIcon></GridLayout.dummyIcon>
        </>
      </GridLayout.gridContainer>
    </GridLayout>
  )
}

export default IconGrid
