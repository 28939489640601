import React from 'react'
import Layout from './feature-icons.styles'
import InfoIcon from '../../components/info-icon'

const FeatureIcons = () => {
  return (
    <Layout>
      <Layout.container>
        <InfoIcon
          icon='web'
          title='Beautiful Themes'
          subtitle='All our themes are professionally designed in house'
        />
        <InfoIcon
          icon='open_with'
          title='Easy Editing'
          subtitle='Simply drag and drop your own content to build your site'
        />
        <InfoIcon
          icon='equalizer'
          title='Powerful Stats'
          subtitle='Powerful analytics so you can see how your site is performing'
        />
      </Layout.container>
    </Layout>
  )
}

export default FeatureIcons
