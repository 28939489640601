import React from 'react'
import Layout from '../components/layout'
import FeatureIcons from '../components/features-icons'
import PricingCards from '../components/pricing-cards/pricing-cards'
import HeroImage3 from '../images/hero-3.jpg'
import ImageBanner from '../components/image-banner/image-banner'
import { FEATURE_TEXT } from '../helpers/constants'
import CTA from '../components/cta'
import SEO from '../components/seo'
import IconGrid from '../components/icon-grid'

const {
  FEATURE_HERO_TITLE,
  FEATURE_HERO_SUBTITLE,
  CTA_TITLE,
  CTA_BUTTON,
} = FEATURE_TEXT

const Features = () => {
  return (
    <Layout>
      <SEO title='Features' pageType='home' flowType='none' />
      <ImageBanner
        isFeatureHero
        imageSrc={HeroImage3}
        title={FEATURE_HERO_TITLE}
        subtitle={FEATURE_HERO_SUBTITLE}
      />
      <FeatureIcons />
      <CTA title={CTA_TITLE} button={CTA_BUTTON} label={'features'} />
      <IconGrid />
      <PricingCards hasDisclaimer />
    </Layout>
  )
}

export default Features
