export const ICON_CONTENTS = [
  {
    id: 1,
    icon: 'cloud_queue',
    title: 'HOSTING',
    subtitle:
      'Your website will be hosted on our secure and reliable servers. Saving you money and the worry of another thing to organize.',
  },
  {
    id: 2,
    icon: 'web',
    title: 'DOMAINS',
    subtitle:
      'The best thing you can do for your website is give it a custom domain. It looks professional and makes it easier for customers to find you.',
  },
  {
    icon: 'people',
    id: 3,
    title: 'SOCIAL',
    subtitle:
      "We help you increase the public's awareness of your brand by providing quick links to set up social media accounts and pages for your site.",
  },
  {
    icon: 'search',
    id: 4,
    title: 'SEO',
    subtitle:
      'We can help you quickly index your site and have it ranked with all popular search engines straight away, all with our powerful SEO tools.',
  },
  {
    icon: 'perm_media',
    id: 5,
    title: 'GALLERIES',
    subtitle:
      "Inventive ways to display your images come as standard with our themes. You'll also have instant access to our free image library.",
  },
  {
    icon: 'shopping_cart',
    id: 6,
    title: 'ECOMMERCE',
    subtitle:
      'Start selling on your website in minutes by adding an online shop. Simple features help you add products, take payments, and customize your store.',
  },
  {
    icon: 'create',
    id: 7,
    title: 'BLOG',
    subtitle:
      'Start voicing your views, keep people up to date on your hobbies and interests, or begin posting articles today with our quick and easy blog site set up.',
  },
  {
    icon: 'assessment',
    id: 8,
    title: 'ANALYTICS',
    subtitle:
      'We know how important the traffic your site receives is, so our Google Analytics integrated tool gives you the stats you need instantly.',
  },
  {
    icon: 'chat_bubble_outline',
    id: 9,
    title: 'SUPPORT',
    subtitle:
      'Our support team is here to answer any questions you may have, however small. We understand how important it is to get the right answers quickly.',
  },
]
