import styled from 'styled-components'
import theme, { media } from '../../helpers/theme'

const FeatureIcons = styled.div`
  margin: 0 auto;
  padding: ${theme.space[16]}px ${theme.space[2]}px ${theme.space[4]}px;

  ${media(768)} {
    width: 750px;
  }

  ${media(992)} {
    width: 970px;
    flex-direction: row;
  }

  ${media(1200)} {
    width: 1170px;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;

  ${media('sm')} {
    flex-direction: row;
  }
`

FeatureIcons.container = Container
export default FeatureIcons
