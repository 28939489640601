import styled from 'styled-components'
import theme, { media } from '../../helpers/theme'

const GridLayout = styled.div`
  padding: ${theme.space[10]}px 0;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12);
`

GridLayout.gridContainer = styled.div`
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
  padding-bottom: ${theme.space[8]}px;

  .icon {
    font-size: ${theme.font['4xl']};
    width: auto;
    height: auto;
    color: ${theme.colors.turqoise};
  }

  ${media(768)} {
    width: 750px;
  }

  ${media(992)} {
    width: 970px;
  }

  ${media(1200)} {
    width: 1170px;
  }
`

GridLayout.iconContainer = styled.div`
  display: flex;
  color: ${theme.colors.font.lightgray};
  flex-direction: column;
  min-width: 330px;
  flex-basis: 25%;
  text-align: center;
  padding: ${theme.space[5]}px ${theme.space[3]}px;
`

GridLayout.iconTitle = styled.div`
  font-size: ${theme.font['base']};
  padding: ${theme.space[3]}px 0;
  text-transform: capitalize;
`

GridLayout.dummyIcon = styled.div`
  min-width: 330px;
  flex-basis: 25%;
  padding: ${theme.space[5]}px ${theme.space[3]}px;

  ${media('xl')} {
    display: none;
  }
`

GridLayout.iconSubtitle = styled.div`
  font-size: ${theme.font['sm']};
  text-align: center;
  color: ${theme.colors.gray};
  line-height: ${theme.space[6]}px;
  padding: 0 ${theme.space[4]}px;

  ${media('sm')} {
    text-align: left;
  }
`

export default GridLayout
